.sn_gallery {

	&_overlay {
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: $zindex-sticky + 50;
		overflow: hidden;
		padding: 40px;
		transition: $transition-smooth;
		user-select: none;
		transform: translate3d(0, 100%, 0);

		@include mq(md){
			padding: 70px;
		}

		&._open {
			transform: translate3d(0, 0, 0);
		}

		&_bd {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background: rgba(#000, 0);
			transition: $transition-smooth;
			transition-delay: 0;

			._open & {
				background: rgba(#000, .6);
				transition-delay: .25s;
			}
		}

		&_close {
			@include size(28px);
			position: absolute;
			top: 15px; right: 15px;
			fill: #fff;

			&:hover,
			&:focus,
			&:active {
				fill: $primary;
			}
		}

		.swiper-container {
			@include size(100%);
			overflow: visible;
			max-width: 1920px;
			margin: 0 auto;
			pointer-events: none;

			.swiper-wrapper {
				align-items: stretch;

				.swiper-slide {
					height: auto;
					position: relative;

					img {
						position: absolute;
						// border-radius: $border-radius;
						pointer-events: auto;

						&._h {
							@include size(100%, auto);
							left: 0;
							top: 50%;
							transform: translateY(-50%);
						}

						&._v {
							@include size(auto, 100%);
							top: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			.swiper-button-prev {
				left: auto;
				right: 100%;
				margin-right: 5px;

				@include mq(md){
					margin-right: 10px;
				}
			}

			.swiper-button-next {
				right: auto;
				left: 100%;
				margin-left: 5px;

				@include mq(md){
					margin-left: 10px;
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				pointer-events: auto;

				@include mq(0px, sm-max){
					@include size(30px);
					padding: 8px;
					margin-top: -15px;
				}
			}
		}
	}
}
