.sn_block_slider_quotes {
	background: #eee6d9;
	padding: 65px 0;

	&_ct {
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	&_title {
		@extend .h1;
		text-align: center;
		margin-bottom: 30px;
	}

	&_card {
		padding-left: 20px;
		position: relative;
		height: 100%;

		&::before {
			@include scut-triangle(down, 40px 20px, #fff);
			content: '';
			position: absolute;
			top: 0;
			left: 0;
		}

		&_in {
			position: relative;
			z-index: 1;
			background: #fff;
			border-radius: 0 20px 20px 20px;
			color: $gray-600;
			padding: 38px 24px 36px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include mq(md){
				padding: 40px 47px 30px;
			}

			@include mq(lg){
				padding: 30px 37px 30px;
			}

			@include mq(xl){
				padding: 40px 40px 30px;
			}

			.sn_sprite._quote {
				@include size(20px);
				margin-bottom: 10px;
				fill: $gray-600;
			}

			p {
				font-size: 14px;
			}
		}

		&_bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 1.3;

			.author {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 400;
				letter-spacing: 1.2px;
			}

			.date {
				font-size: 10px;
			}

			.sn_sprite {
				@include size(30px);
				padding: 4px;
				border-radius: 100%;
				fill: #fff;

				&._facebook-2 { background: #3b5998; }
				&._trip-advisor { background: #5a9445; }
				&._booking { background: #074880; }
			}

			img {
				display: block;
				width: 30px;
			}
		}
	}

	.swiper-container {
		.swiper-wrapper {
			align-items: stretch;

			.swiper-slide {
				height: auto;
			}
		}

		.swiper-pagination {
			position: relative;
			bottom: 0;
			margin-top: 40px;
			margin-bottom: 8px;

			&.swiper-pagination-bullets {
				.swiper-pagination-bullet {
					border: 2px solid $secondary;
				}
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			@include size(30px, 30px);
			margin-top: 0;
			top: auto;
			bottom: 0;
			fill: $gray-900;
		}
	}
}
