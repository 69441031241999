// Variables
$plyr-color-main: $primary;
$plyr-font-family: $font-family-base;

// Styles
.plyr {

	.plyr__poster {
		background-size: cover;
	}

	.plyr__control {
		&.plyr__control--overlaid {
			@include size(60px);
			border: 2px solid #fff;
			background: rgba(#fff, 0);
			padding: 0;
			display: flex !important;
			align-items: center;
			justify-content: center;

			@include mq(md){
				@include size(100px);
				border: 4px solid #fff;
			}

			svg {
				@include size(30px);
				left: 3px;

				@include mq(md){
					@include size(50px);
					left: 4px;
				}
			}

			&:hover,
			&:focus,
			&:active {
				border-color: $plyr-color-main;
			}
		}
	}

	.plyr__controls__item button,
	.plyr__controls__item.plyr__control {
		border-radius: 1000px;

		.icon--not-pressed {
			position: relative;
			left: 1px;
		}
	}

	video {
		height: 100% !important;
	}
}
