.sn_map {
	background-size: cover;
	background-position: center center;

	&._footer {
		height: 300px;

		iframe {
			height: 300px;
			width: 100%;
		}
	}

	&._where_we_are {
		height: 400px;

		@include mq(md){
			height: 600px;
		}

		iframe {
			height: 400px;
			width: 100%;

			@include mq(md){
				height: 600px;
			}
		}
	}
}
