.sn_header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: $zindex-fixed;
	transition: $transition-smooth;

	&_backdrop {
		background-color: rgba(#292927, .4);
		position: fixed;
		top: 0;
		left: 0;
		@include size(100%);
		z-index: $zindex-fixed - 2;
		display: none;

		&._open {
			display: block;
			opacity: 1;
		}
	}

	&._scrolled {
		position: fixed;
		background-color: rgba(#5e778e, .8);
	}

	.navbar {
		height: $navbar-height-mobile;
		padding-left: 0;
		padding-right: 0;

		@include mq(lg) {
			height: $navbar-height-desktop;
		}

		> .container {
			justify-content: flex-start;
		}

		.navbar-toggler {
			padding: 0;
			position: relative;
			outline: 0 !important;
			margin-right: 20px;
			color: #fff;
			transition: $transition-base;

			&:hover {
				color: $primary;
			}

			@include mq(lg) {
				margin-right: 30px;
			}

			&::after {
				content: "MENU";
				color: currentColor;
				font-size: 8px;
				letter-spacing: 5px;
				margin-top: 10px;
				width: 37px;
				display: block;

				@include mq(lg) {
					width: 68px;
					font-size: 13px;
					letter-spacing: 10.66px;
					margin-top: 14px;
				}
			}

			span {
				display: block;
				height: 2px;
				width: 37px;
				border-radius: 10px;
				background-color: currentColor;
				margin-top: 10px;

				@include mq(lg) {
					width: 68px;
					height: 3px;
					margin-top: 18px;
				}

				&:first-child {
					margin-top: 0;
				}
			}
		}

		.navbar-brand {
			margin: 0 auto 0 0;
			padding: 0;

			img {
				max-width: 90px;
				max-height: calc(#{$navbar-height-mobile} - #{$navbar-padding-y} * 2);

				@include mq(sm) {
					max-width: 200px;
					max-height: calc(#{$navbar-height-desktop} - #{$navbar-padding-y} * 2);
				}

				@include mq(lg) {
					max-width: none;
					max-height: calc(#{$navbar-height-desktop} - #{$navbar-padding-y} * 2);
				}
			}
		}
	}

	&_button {

		.btn {
			font-size: 13px;

			@include mq(md) {
				font-size: 16px;
			}

			@include mq(xl) {
				font-size: 20px;
			}
		}
	}

	&_tools {
		display: flex;
		align-items: center;

		&_icons {
			display: flex;
			margin-left: 25px;

			a {
				display: block;
				@include size(26px);
				margin: 0 10px;
				fill: #fff;

				&:hover {
					fill: $primary;
				}
			}
		}

		&_langs {
			margin-left: 10px;

			&_menu {
				margin: 0;
				padding: 0;
				list-style: none;
				@include font-size(15px);
				line-height: 1;
				display: flex;

				li {
					border-right: 1px solid #fff;
					padding: 0 5px;

					&:last-child {
						border-right: 0;
					}

					&._active {

						a {
							pointer-events: none;
							font-weight: fw(bold);
						}
					}

					a {
						color: #fff;
						text-transform: uppercase;

						&:hover {
							color: $primary;
						}
					}
				}
			}

			&_dropdown {

				.dropdown-toggle {
					color: #fff;
					text-transform: uppercase;
					font-weight: fw(bold);
					@include font-size(15px);

					&:hover {
						color: $primary;
					}
				}
			}
		}
	}

	&_menu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: #355573;
		box-shadow: 0 0 15px 10px rgba(#000, .4);

		&_top {
			height: $navbar-height-mobile;
			display: flex;
			align-items: center;

			@include mq(md) {
				border-bottom: 1px solid $gray-500;
			}

			@include mq(lg) {
				height: $navbar-height-desktop;
			}

			.navbar-brand {
				margin: 0 0 0 auto !important;
			}
		}

		&_close {
			color: #fff;
			display: flex;
			text-transform: uppercase;
			align-items: center;
			@include font-size(14px, 20px, lg);
			letter-spacing: 0.1em;
			font-weight: fw(regular);

			&:hover{
				color: $primary;
			}

			.toggle {
				display: block;
				@include size(15px);
				position: relative;
				margin-left: 10px;

				@include mq(lg) {
					@include size(22px);
				}

				&::before {
					content: "";
					display: block;
					background-color: currentColor;
					width: 21px;
					height: 1px;
					position: absolute;
					top: 0;
					left: 0;
					transform: rotate(45deg);
					transform-origin: 0 0;

					@include mq(lg) {
						width: 30px;
					}
				}

				&::after {
					content: "";
					display: block;
					background-color: currentColor;
					width: 21px;
					height: 1px;
					position: absolute;
					top: 0;
					right: 0;
					transform: rotate(-45deg);
					transform-origin: 100% 100%;

					@include mq(lg) {
						width: 30px;
					}
				}
			}
		}

		&_tools {
			border-top: 1px solid $gray-500;
			background-color: $gray-200;

			.sn_header_tools {
				height: 48px;

				&_icons {
					margin-left: 0;

					a {
						@include size(20px);
						fill: $gray-700;

						&:hover {
							fill: $primary;
						}
					}
				}

				&_langs {
					margin-left: auto;
					margin-right: 10px;

					&_menu {
						@include font-size(12px);

						li {
							border-right: 1px solid $gray-700;

							&:last-child {
								border-right: 0;
							}

							a {
								color: $gray-700;

								&:hover {
									color: $primary;
								}
							}
						}
					}

					&_dropdown {

						.dropdown-toggle {
							color: $gray-700;
							@include font-size(12px);

							&:hover {
								color: $primary;
							}
						}
					}
				}
			}
		}

		&_bottom {
			max-height: calc(100vh - #{$navbar-height-mobile} - 48px);
			overflow-y: auto;

			@include mq(lg) {
				max-height: calc(100vh - #{$navbar-height-desktop});
			}
		}

		.navbar-nav {
			text-align: center;
			padding-top: 10px;
			padding-bottom: 20px;

			@include mq(md) {
				padding-top: 50px;
			}

			.nav-item {
				border-bottom: 1px solid #dbd7d6;
				// display: flex;
				// justify-content: space-between;
				// flex-wrap: wrap;

				&:last-child {
					border-bottom: 0;
				}

				&.current-menu-item {

					.nav-link {
						color: $primary;
						font-weight: fw(regular);
					}
				}

				.nav-link {
					font-size: 15px;
					color: #fff;
					font-weight: fw(light);
					// text-transform: uppercase;
					display: inline-block;

					@include mq(lg) {
						font-size: 22px;
					}

					&:hover {
						color: $primary;
					}
				}

				.dropdown-toggle {
					color: #fff;
					margin-left: 12px;

					&:hover {
						color: $primary;
					}
				}

				.dropdown-menu {
					background-color: #355573;
					box-shadow: none;
					margin: 0;
					padding: 0;
					text-align: center;
					width: 100%;

					&::before {
						display: none;
					}

					.dropdown-item {
						color: #fff;
						padding: 0 0 5px;
						border: 0;
						text-transform: none;

						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}
}
