.sn_block_background_quote {
	text-align: center;
	position: relative;
	padding: 70px 30px 50px;
	overflow: hidden;

	&_bg {
		position: absolute;
		top: -7px; left: -7px; right: -7px; bottom: -7px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		filter: blur(7px);

		&::before {
			content: '';
		  position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background: rgba(#3e3c37, .35);
		}
	}

	&_ct {
		position: relative;
		z-index: 1;
		opacity: 0;
		filter: blur(15px);
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			filter: blur(0);
		}

		.quote {
			@extend .sn_text;
			padding: 45px 0;
			position: relative;

			h5{
				font-family: $opensans;
				font-style: italic;
			}

			.sn_sprite {
				@include size(24px);
				content: '';
				position: absolute;
				left: 50%;
				margin-left: -12px;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				fill: #fff;

				&._quote-open {
					top: 0;
				}

				&._quote-close {
					bottom: 0;
				}
			}
		}

		.author {
			text-transform: uppercase;
			font-family: $font-family-serif;
			font-size: 20px;
			margin-top: 25px;
			font-style: italic;

			&::before {
				content: '- ';
			}

			&::after {
				content: ' -';
			}
		}
	}

	&, * {
		color: #fff;
	}
}
