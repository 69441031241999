.sn_blog_grid {

	&_filters {
		background: $gray-400;
		padding: 20px 0;

		&_ct {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.sn_form{
			display: inline-flex;
			align-items: center;
			margin: 0 15px 0 0;

			@include mq(md){
				max-width: 330px;
				margin: 0 30px 0 0;
			}

			.form-group {
				// width: 100%;
				margin-bottom: 0;

				.form-control {
					width: 100%;
					flex-grow: 1;
					border-radius: 1000px;
					height: 44px;
					font-size: 16px;
					color: $gray-500;
					padding: 2px 15px;

					@include placeholder {
						font-style: italic;
					}
				}

			}

			button {
				@include size(21px);
				flex-shrink: 0;
				fill: $body-color;
				padding: 0;
				background: none;
				border: 0;
				margin-left: 5px;

				@include mq(md){
					margin-left: 10px;
				}

				&:hover {
					fill: $primary;
				}
			}
		}

		.dropdown {
			.dropdown-toggle {
				padding: 5px 8px;

				@include mq(md){
					padding: 6px 10px;
				}
			}
			.dropdown-menu {
				min-width: 290px;
			}
		}
	}
}
