.sn_offers_grid {

	&_filters {
		background: $gray-400;

		&_btns {
			text-align: center;
			padding: 20px;
			display: none;

			@include mq(md){
				display: block;
			}

			.btn {
				color: $gray-900;
				border-color: $gray-900;
				font-size: 16px;

				&:hover {
					color: #fff;
					border-color: $gray-500;
					background: $gray-500;
				}

				&._active {
					color: #fff;
					border-color: $gray-900;
					background: $gray-900;
					pointer-events: none;
				}
			}
		}

		&_accordion {
			@include mq(md){
				display: none;
			}

			&_toggle {
				padding: 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-transform: uppercase;
				font-family: $font-family-serif;
				color: $gray-900;
				font-size: 16px;

				.sn_sprite {
					@include size(15px);
					transition: $transition-base;
					transform: rotate3d(1, 0, 0, 180deg);
				}

				&.collapsed {
					.sn_sprite {
						transform: rotate3d(1, 0, 0, 0deg);
					}
				}
			}

			&_collapse {
				a {
					text-align: center;
					display: block;
					padding: 15px;
					text-transform: uppercase;
					font-family: $font-family-serif;
					color: $gray-900;
					background: $gray-200;
					font-size: 16px;
					border-bottom: 2px solid $gray-400;

					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}
	}
}
