.sn_sprite {
	@include size(100%);
  display: inline-block;
	vertical-align: top;

	svg {
		@include size(100%);
		display: inline-block;
		vertical-align: top;
		fill: inherit;
	}
}
