.sn_popup {
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	z-index: $zindex-sticky + 50;
	overflow: hidden;
	transition: $transition-smooth;
	user-select: none;
	transform: translate3d(0, 100%, 0);

	&._open {
		transform: translate3d(0, 0, 0);
	}

	&_bd {
		// position: absolute;
		// top: 0; left: 0; right: 0; bottom: 0;
		background: rgba(#000, 0);
		transition: $transition-smooth;
		transition-delay: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow-y: auto;

		._open & {
			background: rgba(#000, .6);
			transition-delay: .25s;
		}
	}

	&_close {
		@include size(28px);
		position: absolute;
		top: 15px; right: 15px;
		fill: #fff;
		z-index: 10;

		&:hover,
		&:focus,
		&:active {
			fill: $primary;
		}
	}

	&_ct {
		position: relative;
		z-index: 1;
		margin: auto;
		padding: 15px;
		pointer-events: none;
		flex-grow: 1;

		@include mq(md){
			padding: 30px 70px;
		}

		* {
			pointer-events: auto;
		}
	}

	&_video {
		width: 100%;
		max-width: 750px;
		margin: auto;
		position: relative;
	}

	&_text {
		width: 100%;
		max-width: 700px;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		padding: 25px;
		min-height: 450px;
		margin: auto;

		@include mq(md){
			padding: 35px;
			min-height: 600px;
		}

		&_in {
			position: relative;
			z-index: 5;
			width: 100%;
		}
	}

	&_bg {
		position: absolute;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		z-index: 1;
		top: 0; left: 0; right: 0; bottom: 0;

		&._blur {
			top: -7px; left: -7px; right: -7px; bottom: -7px;
			filter: blur(7px);
		}
	}

	&_btn {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
	}

	.btn {
		background: #3fc035;
		border-color: #3fc035;
		color: #fff;

		&:hover,
		&:focus,
		&:active {
			background: darken(#3fc035, 10%);
			border-color: darken(#3fc035, 10%);
		}
	}

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		color: #fff;
		font-weight: fw(bold);
		text-align: center;
		margin-bottom: 35px;
	}

	.sn_form {
		max-width: 330px;
		margin: 0 auto;
	}
}
