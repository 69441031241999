.sn_block_slider_gallery {

	&_ct {
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	&_toggle {
		height: 210px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		margin-bottom: 30px;

		@include mq(md){
			display: none;
		}

		.btn {
			border: 2px solid #fff;
			color: #fff;
		}

		&:hover,
		&:active {
			.btn {
				border-color: $primary;
				background: $primary;
			}
		}
	}

	.swiper-container {
		display: none;

		@include mq(md){
			display: block;
		}

		.swiper-wrapper {
			.swiper-slide {
				width: auto;

				a {
					display: inline-block;
				}

				img {
					display: block;
					height: 255px;
					width: auto;

					@include mq(lg){
						height: 355px;
					}
				}
			}
		}
	}
}
