.sn_room_detail {

	&_info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		@include font-size(14px);
		color: $gray-500;
		font-family: $font-family-serif;

		.date {

			span {
				display: inline-block;
				margin: 0 10px;
			}
		}
	}
}
